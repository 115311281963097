<template lang="pug">
    .content__main
        ChangeMyName
</template>

<script>
import ChangeMyName from "../components/person/change-my-name/change-my-name";

export default {
    name: "MyName",
    components: { ChangeMyName },
    data: () => ({
        loading: false,
    }),
    watch: {
        $route() {
            this.redirect();
        },
    },
    methods: {
        redirect() {
            if (this.$store.getters["user/name"] !== "Newbie") {
                this.$router.push("/");
            }
        },
    },
};
</script>
